<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Nominación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Nominaciones</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Nominación</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item" v-if="form.id">
                    <a
                      class="nav-link"
                      id="tab-Detalles"
                      data-toggle="tab"
                      href="#Detalles"
                      >Detalles</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-4">
                          <label>Nombre</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            disabled
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Sitio</label>
                          <v-select
                            :class="[
                              $v.form.sitio.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.sitio"
                            placeholder="Sitio"
                            label="nombre"
                            :options="listasForms.sitios"
                            class="form-control form-control-sm p-0"
                            :disabled="form.id && !this.permite_editar"
                            @input="validarDatos"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-2">
                          <label>Fecha Inicial</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_ini"
                            :class="
                              $v.form.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.id && !this.permite_editar"
                            @input="validarDatos"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label>Fecha Final</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_fin"
                            :class="
                              $v.form.fecha_fin.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.id && !this.permite_editar"
                            @input="validarDatos"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label>Observación</label>
                          <textarea
                            class="form-control form-control-sm"
                            v-model="form.observacion"
                            :class="
                              $v.form.observacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.id && !this.permite_editar"
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Estado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.estado"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              !form.id ||
                                !this.permite_editar ||
                                (form.id && this.cant_detalles == 0)
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo_documento in listasForms.estados"
                              :key="tipo_documento.numeracion"
                              :value="tipo_documento.numeracion"
                            >
                              {{ tipo_documento.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Detalles" v-if="form.id">
                    <div class="card-body">
                      <NominacionDetalles></NominacionDetalles>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="
                      (!form.id &&
                        !this.permite_editar &&
                        !$v.form.$invalid &&
                        $store.getters.can('tep.nominaciones.edit')) ||
                        (form.id &&
                          this.permite_editar &&
                          !$v.form.$invalid &&
                          $store.getters.can('tep.nominaciones.edit'))
                    "
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import NominacionDetalles from "./NominacionDetalles";

export default {
  name: "NominacionForm",
  components: {
    Loading,
    vSelect,
    NominacionDetalles,
  },
  data() {
    return {
      cargando: true,
      nominacion: {},
      permite_editar: true,
      cant_detalles: 0,
      form: {
        nombre: null,
        sitio: {},
        fecha_ini: null,
        fecha_fin: null,
        observacion: null,
        estado: null,
      },
      accion: "",
      metodo: "",
      listasForms: {
        sitios: [],
        estados: {},
        tipo_operacion: [],
        tipo_vehiculo: [],
        transportadoras: [],
        turnos: [],
        areas: [],
      },
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        sitio: {
          required,
        },
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        observacion: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },

  methods: {
    getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.nominacion) {
        this.metodo = "PUT";
        this.nominacion = this.$route.params.nominacion;
        this.form = {
          id: this.nominacion.id,
          nombre: this.nominacion.nombre,
          sitio: this.nominacion.sitio,
          fecha_ini: this.nominacion.fecha_ini,
          fecha_fin: this.nominacion.fecha_fin,
          observacion: this.nominacion.observacion,
          estado: this.nominacion.estado,
        };
        this.permite_editar = true;
        if (this.nominacion.estado == 2) {
          this.permite_editar = false;
        }

        this.cant_detalles = 0;
        if (this.nominacion.tep_det_nominacion) {
          this.cant_detalles = this.nominacion.tep_det_nominacion.length;
        }
        //this.getTurnos();
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
        this.permite_editar = false;
      }
      this.cargando = false;
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    // Listados para los detalles de la nominación
    getTipoOperacion() {
      axios.get("/api/lista/80").then((response) => {
        this.listasForms.tipo_operacion = response.data;
      });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    /*     getTipoVehiculo() {
      axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipo_vehiculo = response.data;
      });
    }, */
    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTransportadoras() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then((response) => {
          this.listasForms.transportadoras = response.data;
        });
    },

    getTurnos(empresa_id) {
      const filtros = {
        sitio_id: this.form.sitio.id,
        empresa_id: empresa_id,
      };
      axios
        .get("/api/tep/nominaciones/contratoTurno", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },
    // Fin - Listados para los detalles de la nominación

    // Validaciones
    validarDatos() {
      // Se validan el sitio
      this.validaNominacionSitio();

      // Se validan las fechas
      this.validaFechasForm();

      // Se valida el nombre de la nominación
      this.getNombre();
    },

    validaNominacionSitio() {
      if (this.form.sitio.id) {
        let me = this;
        axios
          .get("/api/tep/nominaciones/nominacionSitio", {
            params: {
              sitio_id: this.form.sitio.id,
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              const nominacion = response.data[0];
              //   Se pregunta si el estado de la nominación encontrada esta en borrador
              if (nominacion.estado == 1 && this.form.id != nominacion.id) {
                this.$swal({
                  icon: "error",
                  title: `El sitio ${me.form.sitio.nombre} tiene una nominación pendiente por confirmar.`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                me.form.sitio = {};
                me.getNombre();
              }

              //   Se verifica la fecha final de la nominación
              if (me.form.fecha_ini && this.form.id != nominacion.id) {
                let validacion = this.validaFechas(
                  nominacion.fecha_fin,
                  me.form.fecha_ini
                );
                // if (!validacion) {
                //   this.$swal({
                //     icon: "error",
                //     title: `La fecha inicio para la nominación del sitio ${me.form.sitio.nombre} debe ser posterior a "${nominacion.fecha_fin}".`,
                //     toast: true,
                //     position: "top-end",
                //     showConfirmButton: false,
                //     timer: 3000,
                //     timerProgressBar: true,
                //   });
                //   this.form.fecha_ini = null;
                //   me.getNombre();
                // }
              }

              //   Si se esta editando la nominación de verifica el cambio de la fecha inicial
              if (this.form.id == nominacion.id) {
                let validacion = this.validaFechas(
                  me.form.fecha_ini,
                  nominacion.fecha_ini
                );
                // if (validacion) {
                //   this.$swal({
                //     icon: "error",
                //     title: `La fecha inicio para la nominación del sitio ${me.form.sitio.nombre} debe ser a partir de "${nominacion.fecha_ini}".`,
                //     toast: true,
                //     position: "top-end",
                //     showConfirmButton: false,
                //     timer: 3000,
                //     timerProgressBar: true,
                //   });
                //   this.form.fecha_ini = null;
                //   me.getNombre();
                // }
              }
            }
          });
      }
    },

    validaFechasForm() {
      if (this.form.fecha_ini) {
        const actual = moment().format("YYYY-MM-DD");
        const fecha_inicial = new Date(this.form.fecha_ini);
        let validacion = this.validaFechas(actual, fecha_inicial);
        if (!validacion) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser menor a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }

      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);

        // Se valida que la fecha inicial sea menor que la fecha final
        let validacion = this.validaFechas(fecha_menor, fecha_mayor);
        if (!validacion) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechas(menor, mayor) {
      let fecha_menor = new Date(menor);
      let fecha_mayor = new Date(mayor);

      // Se valida que la fecha menor sea menor que la fecha mayor
      if (fecha_menor > fecha_mayor) {
        return false;
      }
      return true;
    },

    getNombre() {
      this.form.nombre = null;
      if (
        this.form.sitio.nombre &&
        this.form.fecha_ini &&
        this.form.fecha_fin
      ) {
        this.form.nombre = `${this.form.sitio.nombre} ${this.form.fecha_ini} - ${this.form.fecha_fin}`;
      }
    },

    // Acciones de formualrios
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.sitio_id = this.form.sitio.id;
        axios({
          method: this.metodo,
          url: "/api/tep/nominaciones",
          data: this.form,
        })
          .then((response) => {
            this.$route.params.accion = "Editar";
            this.$route.params.nominacion = response.data.det_nominacion;
            this.$route.params.nominacion.sitio = this.form.sitio;
            if (this.form.estado == 2) {
              axios({
                method: "POST",
                url: "/api/tep/nominaciones/correo",
                data: this.form,
              })
                .then((response) => {
                  this.$swal({
                    icon: "success",
                    title: "Se a enviado el correo de la nominacion...",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  this.cargando = false;
                })
                .catch((e) => {
                  this.cargando = false;
                  this.$swal({
                    icon: "error",
                    title: `Ha ocurrido un error: ${e.response.data.message}`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                });
            }

            this.getIndex();

            this.$swal({
              icon: "success",
              title: "Se actualizó la nominación correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/tep/Nominaciones");
    },
  },

  async mounted() {
    this.getIndex();
    this.getEstados();
    this.buscarSitios();
    this.getTipoOperacion();
    this.getAreas();
    this.getTipoVehiculo();
    this.getTransportadoras();
  },
};
</script>
