var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Internos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Operaciones")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Nominaciones")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2 border-0"},[_c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Nominación")])])]),_c('div',{staticClass:"card-body p-0"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),(_vm.form.id)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Detalles","data-toggle":"tab","href":"#Detalles"}},[_vm._v("Detalles")])]):_vm._e()]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nombre),expression:"form.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.nombre.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.form.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Sitio")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                            _vm.$v.form.sitio.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                          ],attrs:{"placeholder":"Sitio","label":"nombre","options":_vm.listasForms.sitios,"disabled":_vm.form.id && !this.permite_editar},on:{"input":_vm.validarDatos},model:{value:(_vm.form.sitio),callback:function ($$v) {_vm.$set(_vm.form, "sitio", $$v)},expression:"form.sitio"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Fecha Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_ini),expression:"form.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","disabled":_vm.form.id && !this.permite_editar},domProps:{"value":(_vm.form.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_ini", $event.target.value)},_vm.validarDatos]}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Fecha Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_fin),expression:"form.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","disabled":_vm.form.id && !this.permite_editar},domProps:{"value":(_vm.form.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_fin", $event.target.value)},_vm.validarDatos]}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Observación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observacion),expression:"form.observacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.observacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"disabled":_vm.form.id && !this.permite_editar},domProps:{"value":(_vm.form.observacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observacion", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estado),expression:"form.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.estado.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"disabled":!_vm.form.id ||
                              !this.permite_editar ||
                              (_vm.form.id && this.cant_detalles == 0)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(tipo_documento){return _c('option',{key:tipo_documento.numeracion,domProps:{"value":tipo_documento.numeracion}},[_vm._v(" "+_vm._s(tipo_documento.descripcion)+" ")])})],2)])])])]),(_vm.form.id)?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Detalles"}},[_c('div',{staticClass:"card-body"},[_c('NominacionDetalles')],1)]):_vm._e()])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                    (!_vm.form.id &&
                      !this.permite_editar &&
                      !_vm.$v.form.$invalid &&
                      _vm.$store.getters.can('tep.nominaciones.edit')) ||
                      (_vm.form.id &&
                        this.permite_editar &&
                        !_vm.$v.form.$invalid &&
                        _vm.$store.getters.can('tep.nominaciones.edit'))
                  ),expression:"\n                    (!form.id &&\n                      !this.permite_editar &&\n                      !$v.form.$invalid &&\n                      $store.getters.can('tep.nominaciones.edit')) ||\n                      (form.id &&\n                        this.permite_editar &&\n                        !$v.form.$invalid &&\n                        $store.getters.can('tep.nominaciones.edit'))\n                  "}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]),_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Nominación")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])
}]

export { render, staticRenderFns }